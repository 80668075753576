import { FileExplorer } from "@components/FileExplorer";
import {
	File,
	FileExplorerProps,
} from "@components/FileExplorer/types";
import { Modal } from "@components/modals";
import { Flex } from "@components/ui/Flex";
import React from "react";
import { ModalWrapper } from "../ui/ModalWrapper";
import { useDispatch } from "react-redux";
import { setPathInfo } from "@store/account";

type Props = FileExplorerProps & {
	showModal: boolean;
	toggleClose: () => void;
	onSelectClick: (files: File[]) => void;
};

export const FileBrowserModal = ({
	showModal,
	toggleClose,
	onSelectClick,
	...fileExplorerProps
}: Props) => {
	const onFileDoubleClick = (file: File) => {
		if (!file.isDir && !fileExplorerProps.enableMultiSelect) {
			onSelectClick(fileExplorerProps.selectedFiles);
		}
	};

	const dispatch=useDispatch();

	return (
		<Modal
			title=""
			toggleClose={toggleClose}
			className="file-browser-modal"
			isOpen={showModal}
		>
			<ModalWrapper>
				<Flex height="calc(100% - 50px)">
					<FileExplorer
						onFileDoubleClick={onFileDoubleClick}
						{...fileExplorerProps}
					/>
				</Flex>
				<Flex justifyContent="flex-end" pr="16px" color="#FFF">
					<button className="btn-md" onClick={toggleClose}>
						Cancel
					</button>
					<button
						className="btn-md btn-yellow"
						onClick={() => (onSelectClick(fileExplorerProps.selectedFiles), dispatch(setPathInfo([{ id: "default", name: "default" }])))}
						disabled={!fileExplorerProps.selectedFiles?.length}
					>
						Select
					</button>
				</Flex>
			</ModalWrapper>
		</Modal>
	);
};
