import React, { useEffect, useState } from 'react';
import { Modal, Table, Button, Input, Popconfirm, Checkbox } from 'antd';
import 'antd/dist/antd.css';
import styles from "../../components/formcreators/styles.module.scss"
import { infoAlert, successAlert } from '@components/toastify/notify-toast';

interface FileContentModalProps {
    isOpen: boolean;
    onClose: () => void;
    content: any[]; 
    onSave: (updatedData: any[]) => void; 
    selectedFile: any[];
}

const FileContentModal: React.FC<FileContentModalProps> = ({ isOpen, onClose, content, onSave, selectedFile }) => {
    const [editingKeys, setEditingKeys] = useState<string[]>([]);
    const [updatedContent, setUpdatedContent] = useState<any[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState(1); 
    const [searchText, setSearchText] = useState<string>(''); 
    const [searchedColumn, setSearchedColumn] = useState<string>(''); 
    const [globalSearchText, setGlobalSearchText] = useState<string>(''); // Global search state
    const [hasChanges, setHasChanges] = useState(false);
    const [activeSortColumn, setActiveSortColumn] = useState<string | null>(null);
    const [activeSearchColumn, setActiveSearchColumn] = useState<string | null>(null);
    const [originalContent, setOriginalContent] = useState<any[]>([]); 
    const [filterDropdownVisibleMap, setFilterDropdownVisibleMap] = useState<{ [key: string]: boolean }>({});


    useEffect(() => {
        if (isOpen) {
            const processedContent = content.map((item, index) => {
                const processedItem = Object.entries(item).reduce((acc: any, [key, value]) => {
                    const cleanedKey = key.trim() === '' ? ' ' : key.trim();
                    acc[cleanedKey] = value;
                    return acc;
                }, {});
                return { ...processedItem, key: index.toString() };
            });
            setUpdatedContent(processedContent);
            setOriginalContent(processedContent);
            window.addEventListener('contextmenu', handleRightClick);
            return () => {
                window.removeEventListener('contextmenu', handleRightClick);
            };
        }
    }, [content, isOpen]);

    const isEditing = (record: any) => editingKeys.includes(record.key);

    const handleRightClick = (event: MouseEvent) => {
        const modalElement = document.querySelector('.ant-modal');
        if (!modalElement?.contains(event.target as Node)) {
            event.preventDefault();
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, key: string, dataIndex: string) => {
        const newData = [...updatedContent];
        const index = newData.findIndex((item) => key === item.key);
        if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, { ...item, [dataIndex]: e.target.value });
            setUpdatedContent(newData);
            setHasChanges(true); 

            const originalIndex = originalContent.findIndex((item) => key === item.key);
            if (originalIndex > -1) {
                const updatedOriginal = [...originalContent];
                updatedOriginal.splice(originalIndex, 1, { ...updatedOriginal[originalIndex], [dataIndex]: e.target.value });
                setOriginalContent(updatedOriginal);
            }
        }
    };

    const getSorter = (a: any, b: any, dataIndex: string) => {
        setActiveSortColumn(dataIndex);
        const valueA = a[dataIndex];
        const valueB = b[dataIndex];
    
        // Checking if both values can be converted to numbers
        const numA = Number(valueA);
        const numB = Number(valueB);
        
        // If both values are numbers, performing numeric sorting
        if (!isNaN(numA) && !isNaN(numB)) {
            return numA - numB; // Ascending order
        }
    
        // Otherwise, performing string sorting
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
    };
    
    const hasEmptyColumnTitles = (column: any[]) => {
        return column.slice(1).some(col => col.title.trim() === '');
    };
    // Global Search Handler
    const handleGlobalSearch = (e: React.ChangeEvent<HTMLInputElement>) => {

        const hasEmptyTitle = hasEmptyColumnTitles(columns);

        if (hasEmptyTitle) {
            setGlobalSearchText("")
        } else {
            const value = e.target.value.toLowerCase();
        setGlobalSearchText(value);

        if (value) {
            const filteredData = originalContent.filter((item) =>
                Object.values(item).some((val: any) =>
                    val.toString().toLowerCase().includes(value)
                )
            );
            setUpdatedContent(filteredData.map((item, index) => ({ ...item, key: index.toString() })));
        } else {
            setUpdatedContent(originalContent.map((item, index) => ({ ...item, key: index.toString() })));
        }
        }
    };

    // Search functionality per column
    const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
        setActiveSearchColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
        setActiveSearchColumn(null)
    };

    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => handleReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </div>
        ),
        onFilter: (value: any, record: any) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        filterDropdownVisible: filterDropdownVisibleMap[dataIndex] || false,
        onFilterDropdownVisibleChange: (visible: boolean) => {
            setFilterDropdownVisibleMap((prevState) => ({
                ...prevState,
                [dataIndex]: visible,
            }));
        },
        render: (text: any) => text,
    });

    const handleSaveAllRows = () => {
        if(hasChanges){
            const formattedData = updatedContent.map(({ key, ...rest }) => rest);
            onSave(formattedData); 
            setEditingKeys([]);
            setSelectedRowKeys([]);
            successAlert("Updated Successfully.")
            setHasChanges(false);
        }
    };

    const handleAddRow = () => {
        const newKey = (updatedContent.length + 1).toString();
        const emptyRow = Object.keys(content[0]).reduce((acc: any, key) => {
            acc[key] = '-'; 
            return acc;
        }, { key: newKey });
        const newData = [...updatedContent, emptyRow];
        setUpdatedContent(newData);
        setEditingKeys([...editingKeys, newKey]);
        setSelectedRowKeys([...selectedRowKeys, newKey]); 
        const totalPages = Math.ceil(newData.length / 25); 
        setCurrentPage(totalPages); 
        infoAlert("New row added successfully.")
        setHasChanges(true);
    };

    const handleDeleteSelectedRows = () => {
        const newData = updatedContent.filter((item) => !selectedRowKeys.includes(item.key));
        const originalData = originalContent.filter((item) => !selectedRowKeys.includes(item.key)); // Filter from original data

        setUpdatedContent(newData);
        setOriginalContent(originalData); // Update the original content
        setSelectedRowKeys([]); 
        setEditingKeys(editingKeys.filter((key) => !selectedRowKeys.includes(key))); 
        const formattedData = newData.map(({ key, ...rest }) => rest);
        onSave(formattedData); 
        successAlert('Deleted Successfully.')
        setHasChanges(false);
    };

    const handleRowSelection = (record: any) => {
        const selectedIndex = selectedRowKeys.indexOf(record.key);
        const newSelectedKeys = [...selectedRowKeys];

        if (selectedIndex > -1) {
            newSelectedKeys.splice(selectedIndex, 1); 
        } else {
            newSelectedKeys.push(record.key); 
        }

        setSelectedRowKeys(newSelectedKeys);
        setEditingKeys(newSelectedKeys);
    };

    const columns = (updatedContent && updatedContent.length > 0 
        ? updatedContent 
        : (content && content.length > 0 ? content : []))
.length > 0
    ? [
        {
            title: '',
            dataIndex: 'selection',
            key: 'selection',
            width: 50,
            render: (_: any, record: any) => (
                <Checkbox
                    checked={selectedRowKeys.includes(record.key)}
                    onChange={() => handleRowSelection(record)}
                />
            )
        },
        ...Object.keys((updatedContent && updatedContent.length > 0 ? updatedContent : content)[0]).filter(key => key !== 'key').map((key) => ({
            title: key,
            dataIndex: key,
            key,
            width: Math.max(150, key.length * 10),
            sorter: (a: any, b: any) => getSorter(a, b, key),
            ...getColumnSearchProps(key),
            onHeaderCell: () => ({
                className: (activeSortColumn === key || activeSearchColumn === key) ? 'highlighted-header' : '',
            }),
            render: (text: any, record: any) => {
                const editable = isEditing(record);
                const isNumber = !isNaN(Number(text)) && text !== '';  
                const formattedText = isNumber ? Number(text).toLocaleString('fullwide', { useGrouping: false }) : text;
            
                return editable ? (
                    <Input
                        value={text === '' ? '' : formattedText}  
                        onChange={(e) => handleInputChange(e, record.key, key)}
                        onBlur={(e) => {
                            if (e.target.value === '') {
                                handleInputChange({ target: { value: '' } } as any, record.key, key);
                            }
                        }}
                    />
                ) : (
                    <div>{formattedText}</div>
                );
            }
        }))
    ]
    : [];

    const rowClassName = (record: any) => {
        return selectedRowKeys.includes(record.key) ? 'selected-row' : '';
    };

    const handleSaveAndClose = () => {
        setFilterDropdownVisibleMap({});
            onClose();
    };
    

    return (
        <>
        <Modal
            title={<div className="file-content-modal-header">{selectedFile ? selectedFile[0]?.name : 'File Content'}</div>}
            visible={isOpen}
            onCancel={handleSaveAndClose}
            footer={null}
            centered
            width="90%"
            style={{ height: '80vh', padding: 10, marginTop: 10 }} 
            className="file-content-modal"
        >
            <div className="table-container">
                <div className="action-btns">
                    <Button className="add-btn" onClick={handleAddRow}>
                        <img src="/icons/workflow/add.svg" alt="" />
                        <span>Add Row</span>
                    </Button>
                    {selectedRowKeys.length > 0 && (
                        <>
                            <Button className="save-btn" onClick={handleSaveAllRows}>
                                <span>Save Selected Rows</span>
                            </Button>
                            <Button className="delete-btn" onClick={handleDeleteSelectedRows}>
                                <span>Delete Selected Rows</span>
                            </Button>
                        </>
                    )}
                    <Input
                        placeholder="Search"
                        value={globalSearchText}
                        onChange={handleGlobalSearch}
                        style={{ marginBottom: 16, width: 300 }}
                        className='globalSearch'
                    />
                </div>
                <Table
                    columns={columns}
                    dataSource={updatedContent}
                    pagination={{
                        current: currentPage,
                        pageSize: 25,
                        onChange: (page) => setCurrentPage(page), 
                    }}
                    scroll={{ y: 500, x: '100vw'}}
                    rowClassName={rowClassName}
                    locale={{
                        emptyText: updatedContent.length === 0 
                            ? <div style={{ padding: '10px', color: 'white', textAlign: 'center', backgroundColor:'#2A2C42' }}>No data present</div>
                            : <div style={{ padding: '10px', color: 'white', textAlign: 'center', backgroundColor:'#2A2C42' }}>No matching data for the filter</div>
                    }} 
                />
            </div>
        </Modal>
        </>
    );
};

export default FileContentModal;