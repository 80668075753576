import { ActiveSessionsInfo } from '@api/user-handler';
import { DirectoryInfo } from '../../api/workflow-handler';
import { ExecutionEnvModes } from '../../constants/enums';
import { UserInfo, PathInfos } from '../../services';
import { ResetReducerState } from '../types';

export const GET_USER_INFO = 'GET_USER_INFO';
export const PATH_INFO = 'PATH_INFO';
export const SET_ALL_USERS_LIST = 'SET_ALL_USERS_LIST';
export const SET_USER_DIRECTORIES = 'SET_USER_DIRECTORIES';
export const SET_ACTIVE_SESSIONS = 'SET_ACTIVE_SESSIONS';


export type ActionTypes = GetUserInfo | SetAllUsersList | ResetReducerState | SetUserDirectories | SetActiveSessionsInfo | PathInfo

type GetUserInfo = {
    type: typeof GET_USER_INFO;
    payload: AccountReducerState['activeUserInfo'];
}

type PathInfo = {
    type: typeof PATH_INFO;
    payload: AccountReducerState['pathInfo'];
}

type SetAllUsersList = {
    type: typeof SET_ALL_USERS_LIST;
    payload: AccountReducerState['allUsersList'];
}

export type SetUserDirectories = {
    type: typeof SET_USER_DIRECTORIES;
    payload: DirectoryInfo[];
    key: keyof AccountReducerState['userDirectories'];
}

export type SetActiveSessionsInfo = {
    type: typeof SET_ACTIVE_SESSIONS;
    payload: ActiveSessionsInfo;
}

export type AccountReducerState = {
    activeUserInfo: UserInfo & { isAdmin: boolean };
    pathInfo : PathInfos[];
    allUsersList: { firstName: string; lastName: string; email: string }[];
    userDirectories: Record<ExecutionEnvModes, 
            {
                defaultDirectoryId: number | null;
                directories: DirectoryInfo[];
            }
        >;
    activeSessionsInfo: ActiveSessionsInfo | null;
}