import { File, Folder } from "@components/FileExplorer/types";
import { errorAlert } from "@components/toastify/notify-toast";
import { RootState } from "@store/types";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fileStorage, StorageType } from "./storage";
import { setPathInfo } from "@store/account";

export const useFileStorage = (storageType: StorageType, fileSystemPrefix?: string) => {
	const userInfo = useSelector(
		(store: RootState) => store.AccountReducer.activeUserInfo
	);

	const pathInfo = useSelector(
		(store: RootState) => store.AccountReducer.pathInfo
	);

	const dispatch=useDispatch();

	const [folderChain, setFolderChain] = useState<Folder[]>([{id:'default', name:'default'}]);
	const [files, setFiles] = useState<File[]>([]);
	const [loading, setLoading] = useState(false);
	const [currentPath, setCurrentPath] = useState<string>("default");
	const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

	const fs = useMemo(() => fileStorage[storageType], [storageType]);

	useEffect(() => {
		if (userInfo.email) {
			setFolderChain(pathInfo);
		}

		const validPathInfo = Array.isArray(pathInfo) ? pathInfo : [pathInfo];
		setCurrentPath(
			validPathInfo.map((folder: Folder) => folder.name).join("/")
		);
	}, [pathInfo]);


	useEffect(() => {
		dispatch(setPathInfo([{id:'default', name:'default'}]))
	}, []);


	useEffect(() => {
		refreshFileList();
	}, [currentPath]);

	const refreshFileList = () => {
		if (!currentPath) return;
		setLoading(true);
		fs.getFiles(
			currentPath,
			(fileList) => {
				setFiles(fileList);
				setLoading(false);
			},
			(err) => {
				errorAlert(err?.response?.data?.message);
				setFiles([]);
				setLoading(false);
			}
		);
	};

	return {
		files,
		loading,
		currentPath,
		folderChain,
		selectedFiles,
		setSelectedFiles,
		setFolderChain,
		refreshFileList,
		deleteFile: fs.deleteFile,
		downloadFile: fs.downloadFile,
		uploadFile: fs.uploadFile,
		fileSystemPrefix: fileSystemPrefix || fs.prefix,
	};
};
