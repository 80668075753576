import { Flex } from "@components/ui/Flex";
import { Text } from "@components/ui/Text";
import React, { useState } from "react";
import { Icons } from "./Icons";
import { Search } from "./Search";
import { FileExplorerProps, Folder } from "./types";
import { Breadcrumb } from "./ui/Breadcrumb";
import { Button } from "./ui/Button";
import { DownloadIcon } from "@pages/data-browser/icons";
import { DeleteSVGIcon } from "@pages/cluster_redisign/icons";
import { Select } from 'antd';
import { folder } from "jszip";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/types";
import { setPathInfo } from "@store/account";
const { Option } = Select;

type Props = FileExplorerProps & {
	search?: string;
	setSearch: (text: string) => void;
	searchResultCount: number;
};

export const ActionBar = ({
	folderChain,
	search,
	searchResultCount,
	actionBarOptions,
	setFolderChain,
	setSearch,
	onRefresh,
	onUpload,
	onDownload,
	onDelete,
	view,
}: Props) => {
	const dispatch=useDispatch();
	const userInfo = useSelector(
        (store: RootState) => store.AccountReducer.activeUserInfo
    );
	

	const appArray = [
        { id: "default", name: "default" },
        { id: userInfo?.id, name: userInfo?.username }
    ];

	const [selectedApp, setSelectedApp] = useState(appArray[0]);


	const updateFolderChain = (value: any) => {
        const selectedObject = appArray.find(item => item.id === value)||appArray[0];
		dispatch(setPathInfo([selectedObject]))
        setSelectedApp(selectedObject);
    };

	return (
		<Flex alignItems="center">
			<Flex flex={1} alignItems="center">
				{actionBarOptions?.hideBreadcrumb || (
					<Text
					color="white"
					fontSize={view === "Table" ? "16px" : "12px"}
					fontWeight="bold"
				>
						{(Array.isArray(folderChain) ? folderChain : [folderChain]).map((folder: any) => (
							<Breadcrumb
								key={folder.id}
								onClick={() => updateFolderChain(folder)}
							>
								{folder.name}
							</Breadcrumb>
						))}
				</Text>
				)}
				<Select
					placeholder="Select User"
					onChange={updateFolderChain}
					style={{ width: 200 }}
					value={selectedApp?.id}
				>
						{appArray.map((folder: any) => (
							<Option key={folder.id} value={folder.id}>
							{folder.name}
						</Option>
						))}
					</Select>
				{search && (
					<Flex>
						<Text fontSize="16px" color="white">
							Search Results:&nbsp;
						</Text>
						<Text fontSize="16px" color="#9495A0">
							{searchResultCount} results found
						</Text>
					</Flex>
				)}
			</Flex>
			<Flex gap="16px">
				{actionBarOptions?.hideDownload|| (
					<Button onClick={onDownload} id="file_download">
						<Flex gap="4px" alignItems="center">
							<DownloadIcon />
							Download
						</Flex>
					</Button>
				)}
				{actionBarOptions?.hideDelete || (
					<Button onClick={onDelete} id="file_delete">
						<Flex gap="4px" alignItems="center">
							<DeleteSVGIcon />
							Delete
						</Flex>
					</Button>
				)}
				{actionBarOptions?.hideUpload || (
					<Button onClick={onUpload} id="file_upload">
						<Flex gap="4px" alignItems="center">
							<Icons.upload />
							Upload
						</Flex>
					</Button>
				)}
				{actionBarOptions?.hideRefresh || (
					<Button primary onClick={onRefresh}>
						<Flex gap="4px" alignItems="center">
							<Icons.refresh />
							Refresh
						</Flex>
					</Button>
				)}
				{actionBarOptions?.hideSearch || (
					<Search  search={search || ''} onSearch={setSearch} />
				)}
			</Flex>
		</Flex>
	);
};
