import React, { useState, useEffect, useMemo } from 'react';
import { studioTabs, dataTabs, jobsTabs, TabIcons, adminTabs, Tabs, allTabs } from './enums';
import { useDidUpdate } from 'rooks';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import './styles.scss';
import { Hambuger } from './icons';
import classNames from 'classnames';
import { RootState } from '../../store/types';
import { toggleTreeview, closeActiveForm, closeActiveComponentNotes } from '../../store/workflow';
import { toggleWorkflowsInAnalytics } from '../../store/analytics';
import { TooltipRight } from '../tooltips';
import { NetworkConnectionError } from '../error-page';
import { toggleConnectionsTreeview, toggleAdministratorTreeview, setFileBrowserConnectionList } from '../../store/datasources';
import { MainLoadingSkeleton } from '../loading/main-loading-skeleton';
import { ShowWhenTrue } from '../../helpers';
import { getAllClusters, getDataBricksVersionList, setWorkspaceList } from '../../store/cluster';
import { Env } from '../../constants/settings';
import { UserHandler } from '@api/user-handler';
import { setActiveSessionsInfo, setPathInfo } from '@store/account';
import { CoachMark, ICoachProps } from "react-coach-mark";
import { FileHandler } from '@api/file-handler';
import { WorkSpaceHandler } from '@api/workSpace-handler';

const TIMER_GET_CLUSTERS = 15000;


export const SideBars: React.FC = () => {
    const [activeTab, setActiveTab] = useState({ name: '', url: '' });
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const isDrillingDataExplorer = location.pathname.includes('data-explorer-drilling-app');
    const { goToAnalyticsPage, initialPageLoadSpinner: showInitialPageLoadSpinner } = useSelector((store: RootState) => store.CommonReducer);
    const { activeUserInfo: userInfo } = useSelector((store: RootState) => store.AccountReducer);
    const { isComponentTreeviewMinimized, activeComponentPropertiesInfo } = useSelector((store: RootState) => store.WorkflowReducer);

    const setActiveTabOnPageLoad = () => {
        allTabs.forEach(tab => {
            if (history.location.pathname.includes(tab.url)) {
                setActiveTab(tab);
                return;
            }
        });
    };

    const dispatchAllClusters = () => {
        dispatch(getAllClusters());
    };

    const dispatchUserActiveSessions = () =>{
        UserHandler.GetActiveSessions((response) => {
            dispatch(setActiveSessionsInfo(response));
        })
    }

    const dispatchFileBrowserList = () =>{
        FileHandler.getConnections((response) => {
            dispatch(setFileBrowserConnectionList(response));
        })
    }
    const dispatchWorkspaceList = () =>{
        WorkSpaceHandler.GetWorkSpaceList((response) => {
            dispatch(setWorkspaceList(response));
        })
    }
    useEffect(() => {
        if(Env.databricks) {

            dispatchAllClusters();
            dispatchUserActiveSessions();
            dispatchFileBrowserList();
            dispatchWorkspaceList();
            dispatch(getDataBricksVersionList());
            let timer: NodeJS.Timeout | null = setInterval( () =>{
                dispatchAllClusters();
                dispatchUserActiveSessions();
            }, TIMER_GET_CLUSTERS);
            return () => {
                timer && clearInterval(timer);
                timer = null;
            };
        }

    }, []);

    // const handleUserInfoSuccess = (res: UserInfo) => {
    //     // if(location.pathname === '/')
    //     //     history.push('/workflow');
    //     setUserInfoInStore(dispatch, true, res);
    //     // dispatch(getAllDocs());
    //     dispatch(hideSpinner(SpinnerTypes.initialPageLoadSpinner));
    //     // dispatch(onLoadSuccess(res.id));
    // };

    // const handleUserInfoFailure = () => {
    //     if(location.pathname !== '/' && !location.pathname.includes('reset/password'))
    //         history.push('/', { from: location.pathname });
    //     dispatch(hideSpinner(SpinnerTypes.initialPageLoadSpinner));
    // };

    // useDidMount(() => {
    //     const authTokenExists = Boolean(localStorage.getItem('token'));
    //     if(authTokenExists) {
    //         // if(location.pathname === '/')
    //         //     history.push('/workflow');
    //         // @ts-ignore
    //         // handleUserInfoSuccess()
    //         // UserHandler.GetUserInfo(handleUserInfoSuccess, handleUserInfoFailure);
    //     } else {
    //         handleUserInfoFailure();
    //     }
    // });

    useDidUpdate(() => {
        history.push(studioTabs.tabs.visualizations.url);
    }, [goToAnalyticsPage]);


    useEffect(() => setActiveTabOnPageLoad(), [history.location.pathname]);

    const handleTabClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        //  tabInfo: typeof activeTab
    ) => {
        if (!activeComponentPropertiesInfo.isSaved) {
            e.preventDefault();
            dispatch(closeActiveForm());
        } else if(!activeComponentPropertiesInfo.isNotesSaved) {
            e.preventDefault();
            dispatch(closeActiveComponentNotes())
        }
    };

    function renderTabs(category: Tabs) {
        return (
            <nav className="tabs__box">
                <span className="tabscategory__name">{category.name}</span>
                {Object.entries(category.tabs).map(([tabKey, tabInfo]) => {
                    const Icon = TabIcons[(tabKey as keyof typeof TabIcons)];
                    const isTabActive = activeTab === tabInfo && !showInitialPageLoadSpinner;
                    return (
                        <div key={tabInfo.name} className="tab__item__outer" id={`tab_${tabInfo.name.replace(" ", "_")}`}>
                            <TooltipRight key={tabInfo.name} title={tabInfo.name} mouseEnterDelay={1}>
                                <Link
                                    key={tabInfo.name}
                                    className={`tab__item ${isTabActive ? 'tab__item--active' : ''}`}
                                    onClick={(e: any) => handleTabClick(e,
                                        //  tabInfo
                                    )}
                                    to={tabInfo.url}
                                >
                                    <Icon active={isTabActive} />
                                </Link>
                            </TooltipRight>

                        </div>
                    );
                })
                }
            </nav>
        );
    }

    const isHamburgerEnabled = useMemo(() => (
        history.location.pathname.includes(studioTabs.tabs.visualizations.url) || 
        history.location.pathname.includes(dataTabs.tabs.browser.url) || 
        history.location.pathname.includes(adminTabs.tabs.admin.url) ||
        history.location.pathname.includes(studioTabs.tabs.canvas.url) 
    ), [history.location.pathname]);


    const handleHamburgerClick = () => {
        if(isHamburgerEnabled) {
            const pathname = window.location.pathname;
            if (pathname.includes(studioTabs.tabs.visualizations.url)) dispatch(toggleWorkflowsInAnalytics());
            else if (pathname.includes(dataTabs.tabs.browser.url)) dispatch(toggleConnectionsTreeview());
            else if (pathname.includes(adminTabs.tabs.admin.url)) dispatch(toggleAdministratorTreeview());
            else dispatch(toggleTreeview());
        }
    };

    if(isDrillingDataExplorer){
        return null;
    }
    return (
        <>
            <div 
                className={classNames('fixed-sidebar fixed-sidebar--left', { showSkeleton: showInitialPageLoadSpinner }, { hide: location.pathname === '/blank' && !showInitialPageLoadSpinner})}
                onClick={()=>dispatch(setPathInfo([{id:'default', name:'default'}]))}
            >
                <div
                    id='mini_title'
                >
                    <img src="/icons/sidebar/logo-DS.png" alt="" />
                </div>
                <div 
                    className={classNames('hamburger__icon', {'disabled': !isHamburgerEnabled})} 
                    id='hamburger__icon' 
                    onClick={handleHamburgerClick}
                >
                    <Hambuger className={classNames('reverse__img', isComponentTreeviewMinimized)} />
                </div>
                {renderTabs(studioTabs)}
                {renderTabs(dataTabs)}
                {renderTabs(jobsTabs)}
                {userInfo.isAdmin ?
                    // <div className={classNames({ 'hide': !userInfo.isAdmin && false })}>
                    renderTabs(adminTabs)
                    // </div>
                    :
                    <img className="productOfDeepiQ__img" src="/icons/sidebar/logo-DEEPIQ.png" alt="" />
                }
                
            </div>
            <NetworkConnectionError />
            <ShowWhenTrue show={showInitialPageLoadSpinner}>
                <MainLoadingSkeleton />
            </ShowWhenTrue>
        </>
    );
};